.taskList {
    padding-bottom: 58px;
}

.taskList .am-accordion-content-box {
    padding: 10px;
}

.taskList  .am-accordion-content-box div {
    padding-bottom: 10px;
}

.taskList .am-accordion-content-box div:last-child {
    padding-bottom: 0;
}

.taskList .am-accordion .am-accordion-item .am-accordion-header {
    padding: 0;
}

.taskList .prev-message-title {
    padding-left: 15px;
}

.board-title {
    padding: 15px;
    font-weight: bolder;
    font-size: 16px;
    background-color: #cccccc;
}

#peoples .am-list-item .am-list-line .am-list-extra {
    overflow: visible;
}
