body {
    margin: 0;
}

.DayPickerInput {
    z-index: 3;
}

@media (min-width: 520px) {
    .DayPickerInput-Overlay {
        left: -50%;
    }
}

#groupList .am-list-item .am-list-line .am-list-content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
}

#verify {
    text-align: center;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

#verify .title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

#verify input {
    margin-right: 10px;
    text-align: center;
    height: 30px;
    width: 30px;
    font-size: 20px;
    border: 1px solid #ccc;
}

#verify input:last-child {
    margin-right: 0;
}

#webBoard, #webDueDate, #webStatus {
    overflow: visible;
}

#webBoard .am-list-line,
#webDueDate .am-list-line,
#webStatus .am-list-line {
    overflow: visible;
}

#webBoard .am-list-line .am-list-content,
#webDueDate .am-list-line .am-list-content,
#webStatus .am-list-line .am-list-content {
    overflow: visible;
}

#webBoard .am-flexbox,
#webDueDate .am-flexbox,
#webStatus .am-flexbox {
    overflow: visible;
}

#webDueDate input {
    border: none;
    text-align: right;
}

#webStatus .status {
    width: 21%;
    z-index: 2;
}

#webBoard .board {
    width: 90%;
    z-index: 4;
}

#webStatus .status div:first-child,
#webBoard .board div:first-child {
    border: none;
}

#webStatus .status span,
#webBoard .board span {
    background: none;
}
